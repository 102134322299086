<script>
	import Modal from '../Modal.svelte';
	export let profile = {};
	export let source = 'apple';
	$: hasData = profile && Object.keys(profile).length !== 0;
	let showModal = false;
</script>

{#if hasData}
	<img
		class="imgFlip w-10 h-10"
		src="/logos/{source === 'apple' ? 'apple_health.webp' : `${source}_logo.svg`}"
		alt="{source} profile pic"
	/>
	<button class="px-0 w-full text-green-500" id="logout" on:click={() => (showModal = true)}>
		Connected
	</button>
{/if}

{#if source === 'apple'}
	<Modal bind:show={showModal}>
		<p>
			To disconnect Apple Health, you'll need to go to your iPhone's Apple Health settings and
			disconnect Magicflow there
		</p>
		<p>Once you've done that, click Done to check that it's worked.</p>
		<button class="mt-2" on:click={() => window.location.reload()}>Done</button>
	</Modal>
{/if}
