<script>
	import APILogin from './APILogin.svelte';
	// import EthiLogin from "./EthiLogin.svelte";

	export let sources = ['spotify', 'twitter', 'apple', 'rescuetime', 'google'];
</script>

<div
	class="grid max-w-xl w-full m-auto gap-4 p-2"
	style="grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)) !important;"
>
	{#each sources as source}
		<APILogin {source} />
	{/each}
</div>
