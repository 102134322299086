<script>
	import Modal from '../Modal.svelte';
	export let profile;
	let showModal = false;
</script>

{#if profile && profile.profile_image_url_https}
	<div class="relative flex justify-center">
		<img
			class="imgFlip w-10 h-10 rounded-full"
			src={profile && profile.profile_image_url_https}
			alt="twitter profile pic"
		/>
		<img
			class="absolute right-2.5 -bottom-4 h-5 w-5"
			src="/logos/twitter_logo.svg"
			alt="twitter logo"
		/>
	</div>
{/if}

<button class="px-0 w-full text-green-500" id="logout" on:click={() => (showModal = true)}>
	Connected
</button>

{#if showModal}
	<Modal bind:show={showModal}>
		<p>
			You are connected as
			<a
				class="font-medium lowercase"
				href={'https://twitter.com/' + (profile && profile.screen_name)}
				>@{profile && profile.screen_name}</a
			>
		</p>
		<p>
			To disconnect Twitter, you'll need to go to your Twitter connected apps and disconnect
			Magicflow there
		</p>
		<a href="https://twitter.com/settings/connected_apps">
			<button class="mt-2" id="logout"> Go to Twitter connected apps </button>
		</a>
		<p>
			Once you've done that, click the button below to remove your Twitter access token from
			Magicflow and refresh the page.
		</p>
		<button class="mt-2" on:click={() => window.location.reload()}> Done </button>
	</Modal>
{/if}
