<script>
	import Modal from '../Modal.svelte';
	export let profile = {};

	$: hasData = profile && Object.keys(profile).length !== 0;
	async function logout() {
		await localStorage.removeItem('spotify-access');
		window.location.href = '/connect';
	}
	let showModal = false;
</script>

{#if hasData}
	<img
		class="imgFlip w-10 h-10 rounded-full"
		src="/logos/spotify_logo.svg"
		alt="spotify profile pic"
	/>
	<button class="px-0 w-full text-green-500" id="logout" on:click={() => (showModal = true)}>
		Connected
	</button>
{/if}

{#if showModal}
	<Modal bind:show={showModal}>
		<p>
			You are connected as
			<span class="font-medium">{profile && profile?.email}</span>
		</p>
		<p>
			To disconnect Spotify, you'll need to go to your Spotify connected apps and disconnect
			Magicflow there.
		</p>
		<a href="https://www.spotify.com/uk/account/apps/">
			<button class="mt-2">Go to Spotify apps</button>
		</a>
		<p>
			Once you've done that, click the button below to remove your Spotify access token from
			Magicflow and refresh the page.
		</p>
		<button class="mt-2" on:click={logout}>Done</button>
	</Modal>
{/if}
