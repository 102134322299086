<script>
	import LoadingOrb from '../LoadingOrb.svelte';
	import SpotifyProfile from './SpotifyProfile.svelte';
	import TwitterProfile from './TwitterProfile.svelte';
	import Profile from './Profile.svelte';
	import { getTwitterProfile } from '$lib/connectUtils.js';
	import { LiveConnections, UserInfo, DataUploaded } from '$lib/store.js';
	import { appleLogin, oauthLogin, spotifyLogin, getSpotify } from '$lib/connectUtils.js';
	import { storeLocal, checkLocal } from '$lib/utils.js';
	import { goto } from '$app/navigation';
	export let source = undefined;
	export let component = undefined;
	export let redirectOnLogin = undefined;

	let sourceFunctionality = {
		twitter: {
			login: () => oauthLogin('twitter'),
			profile: TwitterProfile,
			getProfile: () =>
				getTwitterProfile().then((profile) => {
					if (profile && profile.data !== 'Not logged in to twitter.')
						$LiveConnections.twitter = profile;
					return profile;
				})
		},
		apple: {
			login: appleLogin,
			profile: Profile,
			getProfile: () =>
				$UserInfo.syncHKEnabled && ($LiveConnections.apple = { connected: $UserInfo.syncHKEnabled })
		},
		google: {
			login: () => oauthLogin('google'),
			profile: Profile,
			getProfile: async () => {
				$LiveConnections.google = (
					$LiveConnections.google ||
					(checkLocal('gcal')?.length && checkLocal('gcal')) ||
					[]
				).filter(
					(e) =>
						dayjs(e.start.dateTime) >
						dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour')
				);
				return $LiveConnections.google;
			}
		},
		spotify: {
			login: () => spotifyLogin(redirectOnLogin),
			profile: SpotifyProfile,
			getProfile: () =>
				getSpotify('me').then((profile) => {
					if (
						profile &&
						profile.data !== 'Not logged in to spotify.' &&
						typeof profile.data === 'object'
					)
						$LiveConnections.spotify = profile;
					return profile;
				})
		},
		whoop: {
			login: () => goto('/whoop'),
			profile: Profile,
			getProfile: () =>
				$LiveConnections.whoop &&
				!($LiveConnections.whoop.needsWhoopCredentials || $LiveConnections.whoop.hasSeenModal)
		},
		rescuetime: {
			login: () => oauthLogin('rescuetime'),
			profile: Profile,
			getProfile: () => $DataUploaded.rescuetime || $LiveConnections.rescuetime
		}
	};
	let promise = sourceFunctionality[source].getProfile();
	$: loggedIn = Object.keys($LiveConnections[source] || {}).length;

	$: if (typeof window !== 'undefined' && source === 'spotify') {
		if (localStorage.getItem('spotify-redirect')) {
			let redirect = localStorage.getItem('spotify-redirect');
			localStorage.removeItem('spotify-redirect');
			goto(redirect);
		}
	}
	let showModal;
</script>

<card
	class="relative bg-black flex flex-col items-center gap-4 font-semibold cursor-pointer"
	on:click|preventDefault={() => (!loggedIn || true ? sourceFunctionality[source].login() : '')}
>
	{#await promise}
		<img class="h-10 w-10 animate-pulse" src="/logos/{source}_logo.svg" alt="{source} logo" />
		<button class="text-yellow-400 animate-pulse">Checking</button>
	{:then profile}
		{#if !loggedIn}
			<img
				class="h-10 w-10 rounded-md"
				src="/logos/{source === 'google' ? 'google_calendar' : source}_logo.{source === 'rescuetime'
					? 'webp'
					: 'svg'}"
				alt="{source} logo"
			/>
			<button>Set up</button>
		{:else if component != undefined}
			<svelte:component this={component} {source} />
		{:else}
			<svelte:component
				this={sourceFunctionality[source].profile}
				profile={$LiveConnections[source]}
				{source}
			/>
		{/if}
	{:catch error}
		<img class="h-10 w-10" src="/logos/{source}_logo.svg" alt="{source} logo" />
		<span class="capitalize">{source}</span>
		<button>Set up</button>
	{/await}
</card>
